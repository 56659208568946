import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/DownLoad',
    name: 'DownLoad',
    component: () => import('../views/login/downLoad.vue')
  },
  {
    path: '/main',
    name: 'main',
    redirect: '/home',
    component: () => import('../views/main.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/index/home.vue')
      },
      {
        path: '/work',
        name: 'work',
        component: () => import('../views/work/index.vue')
      },
      {
        path: '/dataPanel',
        name: 'dataPanel',
        component: () => import('../views/dataPanel/index.vue')
      },
      {
        path: '/customerCenter',
        name: 'customerCenter',
        component: () => import('../views/customer/index.vue')
      },
      {
        path: '/connect',
        name: 'connect',
        component: () => import('../views/customer/connect.vue')
      },
      {
        path: '/hidden',
        name: 'hidden',
        component: () => import('../views/customer/hidden.vue')
      },
      {
        path: '/business',
        name: 'business',
        component: () => import('../views/customer/business.vue')
      }
    ]
  },
  {
    path: '/setting',
    name: 'setting',
    redirect: '/setting/unit',
    component: () => import('../views/settings/index.vue'),
    children: [
      {
        path: '/setting/unit',
        name: 'unit',
        component: () => import('../views/settings/unit/index.vue')
      },
      {
        path: '/setting/build',
        name: 'build',
        component: () => import('../views/settings/build/index.vue')
      },
      {
        path: '/setting/equipType',
        name: 'equipType',
        component: () => import('../views/settings/equip/type.vue')
      },
      {
        path: '/setting/equip',
        name: 'equip',
        component: () => import('../views/settings/equip/index.vue')
      },
      {
        path: '/setting/equipImport',
        name: 'equipImport',
        component: () => import('../views/settings/equip/import.vue')
      },
      {
        path: '/setting/userSetting',
        name: 'userSetting',
        component: () => import('../views/settings/user/userSetting.vue')
      },
      {
        path: '/setting/systemRole',
        name: 'systemRole',
        component: () => import('../views/settings/user/systemRole.vue')
      },
      {
        path: '/setting/fire',
        name: 'fire',
        component: () => import('../views/settings/fire/index.vue')
      },
      {
        path: '/setting/plan',
        name: 'plan',
        component: () => import('../views/settings/plan/index.vue')
      },
      {
        path: '/setting/onsiteLabel',
        name: 'onsiteLabel',
        component: () => import('../views/settings/onsite/onsiteLabel.vue')
      },
      {
        path: '/setting/onsitePlan',
        name: 'onsitePlan',
        component: () => import('../views/settings/onsite/onsitePlan.vue')
      },
      {
        path: '/setting/onsiteTask',
        name: 'onsiteTask',
        component: () => import('../views/settings/onsite/onsiteTask.vue')
      },
      {
        path: '/setting/superRecord',
        name: 'superRecord',
        component: () => import('../views/settings/onsite/superRecord.vue')
      },
      {
        path: '/setting/notice',
        name: 'notice',
        component: () => import('../views/settings/system/notice.vue')
      },
      {
        path: '/setting/version',
        name: 'version',
        component: () => import('../views/settings/system/version.vue')
      },
      {
        path: '/setting/log',
        name: 'log',
        component: () => import('../views/settings/system/log.vue')
      },
      {
        path: '/setting/workOrder',
        name: 'workOrder',
        component: () => import('../views/settings/system/workOrder.vue')
      },
      {
        path: '/setting/maintenance',
        name: 'maintenance',
        component: () => import('../views/settings/maintenance/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
})
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
