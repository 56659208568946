import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // 存储状态 这里放全局参数
  state: {
    loginState: false, // 登陆状态
    msgState: false // 消息接受
  },
  // 状态修改 这里是set方法......
  mutations: {
    setLoginState (state, loginState) {
      state.loginState = loginState
    },
    setMsgState (state, msgState) {
      state.msgState = !msgState
    }
  },
  getters: {
    loginState: state => state.loginState,
    msgState: state => state.msgState
  },
  actions: { // 这里是get方法
  },
  modules: {
  }
})
